export enum Brand {
  DEMO = 'demo',
  AI_BUSINESS = 'aibusiness',
  FARM_PROGRESS = 'farmprogress',
  INFORMATION_WEEK = 'informationweek',
  IOT = 'iot',
  QUANTUM = 'quantum',
  LICENSE_GLOBAL = 'licenseglobal2',
  GAME_DEVELOPER = 'gamedeveloper',
  DARK_READING = 'darkreading',
  NATIONAL_HOG_FARMER = 'nationalhogfarmer',
  MASTER = 'master',
  DEVELOPMENT = 'development',
  FEED_STUFFS = 'feedstuffs',
  BEEF_MAGAZINE = 'beefmagazine',
  LIGHT_READING = 'lightreading',
  NATURAL_PRODUCTS_INSIDER = 'naturalproductsinsider',
  SUPPLY_SIDE_FBJ = 'supplysidefbj',
  SUPPLY_SIDE_SJ = 'supplysidesj',
  FOOD_BEVERAGE_INSIDER = 'foodbeverageinsider',
  NEW_HOPE = 'newhope',
  CHANNEL_FUTURES = 'channelfutures',
  TELECOMS = 'telecoms',
  BATTERY_TECH = 'batterytech',
  POWDER_BULK_SOLIDS = 'powderbulksolids',
  DESIGN_NEWS = 'designnews',
  PACKAGING_DIGEST = 'packagingdigest',
  PLASTICS_TODAY = 'plasticstoday',
  MDDI = 'mddi',
  WASTE360 = 'waste360',
  INSIDE_SELF_STORAGE = 'insideselfstorage',
  CONSTRUCTIO_NEXT = 'constructionext',
  BIO_PROCESS_INTERNATIONAL = 'bioprocessinternational',
  BRANCHING = 'branching',
  FEATURE = 'feature',
  TEST_AUTOMATION = 'testautomation',
  TEST = 'test',
  TEST2 = 'test2',
  TEST3 = 'test3',
  DATA_CENTER_KNOWLEDGE = 'datacenterknowledge',
  IT_PRO_TODAY = 'itprotoday',
  WARDS_AUTO = 'wardsauto',
  NETWORK_COMPUTING = 'networkcomputing',
  SUPERMARKET_NEWS = 'supermarketnews',
  FOOD_SERVICE_DIRECTOR = 'foodservicedirector',
  RESTAURANT_HOSPITALITY = 'restauranthospitality',
  WEALTH_MANAGEMENT = 'wealthmanagement',
  ORGANIC_PRODUCE_NETWORK = 'organicproducenetwork',
  SEATRADE_MARITIME = 'seatrademaritime',
  SEATRADE_CRUISE = 'seatradecruise',
  VITA_FOODS_INSIGHTS = 'vitafoodsinsights',
  FI_GLOBAL = 'figlobal',
  SCMDEMO = 'scmdemo',
  DATA_CENTER_KNOWLEDGE_2 = 'datacenterknowledge2',
  CONNECTING_AFRICA = 'connectingafrica',
  THE_AESTHETIC_GUIDE = 'theaestheticguide',
  NATION_S_RESTAURANT_NEWS = 'nrn',
  BIO_XCONOMY = 'bioxconomy',
  AMERICAN_CITY_COUNTY = 'americancityandcounty',
  URGENT_COMMS = 'urgentcomm',
  CATERSOURCE = 'catersource',
  SPECIAL_EVENTS = 'specialevents',
  WORLD_HEALTH_EXHIBITIONS_INSIGHTS = 'worldhealthexhibitions',
  NO_JITTER = 'nojitter',
  FIN_TECH_FUTURES = 'fintechfutures',
  // PLOP: UI CORE BRAND ENUM
}

export const BRANDS_DIVISION_MARKETS = [
  Brand.FARM_PROGRESS,
  Brand.NATIONAL_HOG_FARMER,
  Brand.FEED_STUFFS,
  Brand.BEEF_MAGAZINE,
]

export enum Theme {
  THEME_1 = 'theme1',
  THEME_2 = 'theme2',
}

export type UIConfig = {
  brand: Brand
  isMarketsDivision: boolean
  theme: Theme
  font: {
    primary: { name: string; url: string }
    secondary: { name: string; url: string }
  }
  colors?: {
    'brand-primary-1'?: string
    'brand-primary-2'?: string
    'brand-primary-3'?: string
    'brand-primary-4'?: string
    'brand-primary-5'?: string
    'brand-secondary-1'?: string
    'brand-secondary-2'?: string
    'brand-secondary-3'?: string
    'brand-secondary-4'?: string
    'brand-secondary-5'?: string
    'brand-tertiary-1'?: string
    'brand-tertiary-2'?: string
    'brand-tertiary-3'?: string
    'brand-tertiary-4'?: string
    'brand-tertiary-5'?: string
  }
}

export type standaloneRegistrationOptions = {
  withNewsletterPromo: boolean
  withPayment: boolean
  withPromptForConsent: boolean
  referrer: string
  history: string
}
