import MarkdownIt from 'markdown-it'
import RemoveMarkdown from 'remove-markdown'

const md = new MarkdownIt('zero').enable(['emphasis'])
// Remove beginning and end <p> tags
const regex = /^(<p>)(.*?)(<\/p>)$/

export const renderMarkdown = (text: string) => {
  const render = md.render(text).trim()
  return render.replace(regex, '$2')
}

export const stripMarkdown = (text: string) => {
  return RemoveMarkdown(text)
}
