import { Bem } from './bem'
import { UIConfig } from './types'

const root = Bem('Provider')

export type RootProps = {
  config?: UIConfig
  theme: string
  inject?: boolean
  children: any
}

export const Main = ({ theme, config, inject, children }: RootProps) => {
  return (
    <main data-theme={theme} className={root()}>
      {inject && (
        <>
          <link rel="stylesheet" href={config?.font.primary.url} />
          <link rel="stylesheet" href={config?.font.secondary.url} />
        </>
      )}

      {children}
    </main>
  )
}
