import { Brand } from '../types'

/**
 * ClientId should be the same as the brand stack ID in Contentstack.
 * Since some stacks were created with inconsistent IDs naming we need to define
 * this exceptions map, so we could have consistent brand codes naming on FE side.
 **/
const clientIdExceptionsMap = new Map<Brand, string>([
  [Brand.NATIONAL_HOG_FARMER, 'national_hog_farmer'],
  [Brand.BEEF_MAGAZINE, 'beef_magazine'],
  [Brand.BIO_PROCESS_INTERNATIONAL, 'bioprocessintl'],
])

const brandELNbrandMapping = new Map<Brand, string>([
  [Brand.LICENSE_GLOBAL, 'licenseglobal'],
])

export const getClientId = (brand: Brand): string => {
  if (clientIdExceptionsMap.has(brand)) {
    return clientIdExceptionsMap.get(brand) as string
  }

  return brand
}

export const getClientIdForELN = (brand: Brand): string => {
  if (brandELNbrandMapping.has(brand)) {
    return brandELNbrandMapping.get(brand) as string
  }
  return getClientId(brand)
}

export const getBrand = (stackId: string): Brand => {
  for (const [brand, id] of clientIdExceptionsMap.entries()) {
    if (id === stackId) {
      return brand
    }
  }

  return stackId as Brand
}
