import { FC } from 'react'

import { Bem } from '../bem'

const withModifiers =
  (blockName: string) =>
  (modifiers: Record<string, string> = {}) =>
    Bem(`Storybook${blockName}`)(modifiers)

type StorybookComponent = FC<{
  tagName?: keyof JSX.IntrinsicElements
  modifiers?: Record<string, string>
  children?: any
}>
type StorybookComponentCreator = (blockName: string) => StorybookComponent

export const createStorybookComponent: StorybookComponentCreator =
  blockName => {
    const storybookBlock = withModifiers(blockName)

    const component: StorybookComponent = ({
      children,
      tagName: Tag = 'div',
      modifiers = {},
      ...rest
    }) => {
      return (
        <Tag className={storybookBlock(modifiers)} {...rest}>
          {children}
        </Tag>
      )
    }
    component.displayName = `Storybook${blockName}`

    return component
  }
export const StorybookPlaceholder = createStorybookComponent('Placeholder')
export const StorybookWrapper = createStorybookComponent('Wrapper')

export const getMockImageUrl = (width: number, height: number) => {
  return `https://picsum.photos/${width}/${height}`
}
