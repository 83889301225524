import React, { FC } from 'react'
import { useHydrated } from './useHydrated'

export const ClientOnly: FC<{
  children: () => React.ReactNode
  fallback?: React.ReactNode
}> = ({ children, fallback = null }) => {
  return useHydrated()
    ? React.createElement(React.Fragment, null, children())
    : React.createElement(React.Fragment, null, fallback)
}

export const ServerOnly: FC<{
  children: () => React.ReactNode
  fallback?: React.ReactNode
}> = ({ children, fallback = null }) => {
  return useHydrated()
    ? React.createElement(React.Fragment, null, fallback)
    : React.createElement(React.Fragment, null, children())
}

export const useClientEffect =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect
